var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "q-btn-group",
          { staticClass: "float-right", attrs: { outline: "" } },
          [
            _c("c-update-btn", {
              attrs: {
                name: "updateBtn",
                data: _vm.updateBtnData,
                btnEditable: _vm.btnEditable,
                flagCondition: _vm.flagCondition,
              },
              on: { back: _vm.back },
            }),
            _c("c-btn", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editable && !_vm.deleteDisabled && _vm.isOld,
                  expression: "editable&&!deleteDisabled&&isOld",
                },
              ],
              attrs: { label: "삭제", icon: "remove" },
              on: { btnClicked: _vm.removePlan },
            }),
            _c("c-btn", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editable && !_vm.disabled && !_vm.btnEditable,
                  expression: "editable&&!disabled&&!btnEditable",
                },
              ],
              attrs: {
                isSubmit: _vm.isComplete,
                url: _vm.completeUrl,
                param: _vm.saveProcess,
                mappingType: "PUT",
                label: "평가완료",
                icon: "check",
              },
              on: {
                beforeAction: _vm.completeProcess,
                btnCallback: _vm.completeProcessCallback,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("c-tab", {
          attrs: {
            type: "tabcard",
            align: "left",
            tabItems: _vm.process.sops,
            inlineLabel: true,
          },
          on: {
            "update:tabItems": function ($event) {
              return _vm.$set(_vm.process, "sops", $event)
            },
            "update:tab-items": function ($event) {
              return _vm.$set(_vm.process, "sops", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    key: tab.componentKey,
                    tag: "component",
                    attrs: {
                      sop: tab.sop,
                      process: _vm.process,
                      param: _vm.param,
                      disabled: _vm.disabled,
                      height: _vm.height,
                      updateBtnData: _vm.updateBtnData,
                      planUpdateBtnData: _vm.planUpdateBtnData,
                    },
                    on: {
                      "update:sop": function ($event) {
                        return _vm.$set(tab, "sop", $event)
                      },
                      "update:process": function ($event) {
                        _vm.process = $event
                      },
                      research: _vm.research,
                    },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }